export function hamburgerMenuButton(buttonEl, menuEl) {
  if ($(buttonEl).length) {
    $("#hamburgerButton").on("click", function () {
      $(this).toggleClass("active");
      $(menuEl).toggleClass("active");
    });

    $('.p-model-hamburger__menu-link').on('click', function () {
      $('#hamburgerButton').removeClass("active");
      $(menuEl).removeClass("active");
    });
  }
}
